import * as React from "react";
import Layout from "../../../components/Layout";
import mailchimp_logo from "../../../img/external-logos/mailchimp_logo.svg";
import litmus_logo from "../../../img/external-logos/litmus_logo.svg";
import explainer_thumb from "../../../img/home-page/ExplainerThumbnail.png";
import { Link } from "gatsby";
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import { CheckCircleIcon } from "@heroicons/react/outline";
import queryString from 'query-string';


const IntegrationsPage = ({location}) => {
  const parsed = queryString.parse(location.search);
  
  const [isOpen, setOpen] = React.useState(parsed.video === 'open')

  return (<Layout 
      pageTitle="Subscribe Sense | Stop losing ConvertKit leads to unconfirmed subscribers" 
      pageDescription="Tools guaranteed to minimize unconfirmed subscribers in ConvertKit."
    >
    <div className="">
      <div className="sm:text-center md:max-w-4xl md:mx-auto lg:col-span-6 lg:text-left mb-10">
        <div className="mt-20 mb-12">
          <span className="mt-1 block tracking-tight font-medium text-center">
            <h1 className="block text-4xl sm:text-5xl xl:text-6xl my-4">
              <span>Stop losing leads to </span><br/>
              <span className="text-indigo-600">Unconfirmed Subscribers.</span>
            </h1>
          </span>
          <h2 className="mt-1 block lg:text-3xl tracking-tight font-medium xs:text-2xl sm:text-2xl xl:text-3xl text-center">
            <span className="block">
              <span className="text-grey-900">Integrate Subscribe Sense with your ConvertKit account and </span>
              <span className="text-indigo-600">grow your list 20% faster, overnight.</span>
            </span>
          </h2>
        </div>
        <div class="mt-12 mb-20">
          <div class="max-w-md mx-auto my-4">
            <a href={`${process.env.GATSBY_SS_APP_DOMAIN}/accounts/signup/?next=/subscriptions/purchase/${process.env.GATSBY_SS_STRIPE_PROD_PRO}/`} class="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700">Start Your Free Trial</a>
          </div>
          <div class="max-w-3xl mx-auto">
            <div className="flex justify-around mx-auto text-md my-2">
              <div className="flex mx-2 items-start">
                <CheckCircleIcon className="text-indigo-600 h-8 w-8"/>
                <span className="mx-2">30 Day Free Trial</span>
              </div>
              <div className="flex mx-2 items-start">
                <CheckCircleIcon className="text-indigo-600 h-8 w-8"/>
                <span className="mx-2">No Credit Card Required</span>
              </div>
              <div className="flex mx-2 items-start">
                <CheckCircleIcon className="text-indigo-600 h-8 w-8"/>
                <span className="mx-2">Get Started in 30 Minutes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 relative sm:max-w-2xl sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center mb-10">
        <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-2xl">
          <React.Fragment>
            <ModalVideo channel='youtube' autoplay={true} isOpen={isOpen} videoId="BQYV-Na-d4s" onClose={() => setOpen(false)} />
            <button
              type="button"
              onClick={()=> setOpen(true)}
              className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="sr-only">Learn how Subscribe Sense works.</span>
              <img
                className="w-full"
                src={explainer_thumb}
                alt="How Subscribe Sense Works Video Thumbnail"
              />
              <span className="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                <svg className="h-20 w-20 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
                  <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                  <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                </svg>
              </span>
            </button>
          </React.Fragment>
        </div>
      </div>
    </div>
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">Proven methods to increase opt-in</p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            ConvertKit's double opt-in keeps your list clean, but many valid leads never confirm. Get the best
            of both worlds by keeping spammers out, while reducing friction for truly interested leads.
          </p>
        </div>
    
        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">

                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Get full insight into unconfirmed subscribers</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">Import historical data to see your confirmation rate before and after adding Subscribe Sense to your marketing tech stack.</dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Provide status updates during signup</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">Let users know if their email bounces, and update them if their subscription was successfully confirmed.</dd>
            </div>
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Link directly to confirmation emails</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">Thank-you pages provide user-specific <Link to="/blog/what-is-a-sniper-link/" className="font-bold underline">sniper links</Link> to send users directly to your confirmation email in their webmail client.</dd>
            </div>
    
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Send reminder email nudges</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">Attention spans are short. Optional reminders give leads more than one chance to find your confirmation email. </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    {/* <ul className="list-disc ml-3 mt-3 text-base text-left text-gray-500 sm:mt-5">
          <li className="mx-0 my-0"><span className="font-bold">Diagnose confirmation email deliverability issues</span> from your dashboard in 2 clicks. </li>
          <li className="mx-0 my-0">Present <span className="font-bold">personalized links</span> to user's mailboxes to find your confirmation email. </li>
          <li className="mx-0 my-0">Send <span className="font-bold">confirmation reminders</span>. </li>
          <li className="mx-0 my-0">Provide a subscribe by inbound mail option to <span className="font-bold">avoid the double opt-in completely</span>.</li>
        </ul> */}
    <section className="bg-indigo-800">
      <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">
          <div className="md:flex-shrink-0">
            <img className="h-12" src={mailchimp_logo} alt="MailChimp"/>
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">We’ve seen double-opt in rates within Mailchimp slip to 39%. This means 61% of people start but do not finish the double opt-in process.</p>
            </div>
            <footer className="mt-8">
              <div className="flex items-start">
                <div className="ml-4">
                  <div className="text-base font-medium text-white">
                    - Mailchimp <a href="https://mailchimp.com/resources/why-single-opt-in-and-an-update-for-our-eu-customers/#heading+what+our+data+shows" target="_blank" rel="noreferrer" className="font-bold underline">
                      resource on double vs single opt-in.
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        <div className="py-12 px-4 border-t-2 border-indigo-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          <div className="md:flex-shrink-0">
            <img className="h-12" src={litmus_logo} alt="Litmus"/>
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-600" fill="currentColor" viewBox="0 0 32 32">
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">We see that in the confirmation failure rates, which are generally in the 20% to 40% range. Those aren’t just numbers. Those are lots of genuinely interested people who will be disappointed when they don’t receive your emails.</p>
            </div>
            <footer className="mt-8">
              <div className="flex items-start">
                <div className="ml-4">
                  <div className="text-base font-medium text-white">
                    - Litmus <a href="https://www.litmus.com/blog/single-opt-in-vs-double-opt-in-case-for-soi/" target="_blank" rel="noreferrer" className="font-bold underline">
                      blog post on double vs single opt-in.
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
    <div class="max-w-4xl mx-auto my-16">
      <a href={`${process.env.GATSBY_SS_APP_DOMAIN}/accounts/signup/?next=/subscriptions/purchase/${process.env.GATSBY_SS_STRIPE_PROD_PRO}/`} class="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700">Start Your Free Trial</a>
    </div>

  </Layout>)
};

export default IntegrationsPage;